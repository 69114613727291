.trustedlsvg:hover .yappared-color {
  fill: #f73746;
}

.trustedlsvg:hover .kando-color-blue {
  fill: #2965c5;
}

.trustedlsvg:hover .energyweb-color {
  fill: #a566ff;
}
.trustedlsvg:hover .energyweb-color-black {
  fill: #000;
}

.trustedlsvg:hover .celsiuspro-color {
  fill: #000;
}

.trustedlsvg:hover .elementum-color {
  fill: #000;
}

.trustedlsvg:hover .yappafi-color {
  fill: #05be70;
}

.kando-color-blue {
  fill: #b8b8b8;
}

.kando-color-white {
  fill: #fff;
}

.trustedlsvg:hover .contrast-color-a {
  fill: #333270;
}
.trustedlsvg:hover .contrast-color-b {
  fill: #333270;
}
.trustedlsvg:hover .contrast-color-c {
  fill: #333270;
}
.trustedlsvg:hover .contrast-color-d {
  fill: #333270;
}
.trustedlsvg:hover .contrast-color-e {
  fill: #333270;
}
.trustedlsvg:hover .contrast-color-f {
  /* fill:"url(#paint0_linear_706_15507)" */
  fill: rgb(138, 105, 226);
}
.trustedlsvg:hover .contrast-color-g {
  /* fill:"url(#paint1_linear_706_15507)" */
  fill: rgb(138, 105, 226);
}
.trustedlsvg:hover .contrast-color-h {
  /* fill:"url(#paint1_linear_706_15507)" */
  fill: rgb(138, 105, 226);
}
.trustedlsvg:hover .contrast-color-i {
  /* fill:"url(#paint3_linear_706_15507)" */
  fill: rgb(92, 47, 218);
}
.trustedlsvg:hover .contrast-color-j {
  fill: white;
}
.mixip-color-white {
  fill: #b8b8b8;
}

.trustedlsvg:hover .mixip-color {
  fill: rgb(92, 47, 218);
}
