/* @import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&subset=latin-ext"); */

body {
  margin: 0;
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-spin {
  -webkit-animation: spin 0.8s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: spin 0.8s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --angle: 45deg;
}
.tagDarkLoading {
  /* border: 1px solid transparent; */
  border-image: conic-gradient(
      from var(--angle),
      rgba(255, 255, 255, 0.038) 0deg 310deg,
      #1f8fff 310deg 360deg
    )
    1 stretch;
}

.tagLightLoading {
  /* border: 1px solid transparent; */
  border-image: conic-gradient(
      from var(--angle),
      rgb(247, 251, 255) 0deg 310deg,
      #1f8fff 310deg 360deg
    )
    1 stretch;
}

@supports (background: paint(houdini)) {
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  .tagDarkLoading,
  .tagLightLoading {
    animation: rotate 4s linear infinite;
  }
}

.error-toast ul {
  /* margin-top: 10px; */
  padding: 5px;
  /* color: #2a2a2a; */
  border-radius: 6px;
  font-size: 0.85rem;
}

.error-toast ul li {
  list-style: none;
}

.xterm {
  padding: 10px;
  color-scheme: dark;
}

.ap-absolute.ap-w-full.ap-border-b.ng-star-inserted {
  display: none !important;
}

.recharts-legend-item-text {
  font-size: 0.7rem !important;
}

.alice-carousel__stage-item {
  min-height: 100%;
}

.scroll-area-viewport > div {
  display: block !important;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: #555;
}

.dark ::-webkit-scrollbar-track {
  background-color: #333;
}

.dark ::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
