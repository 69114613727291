.reactflow-parent-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.reactflow-parent-wrapper .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}
